#menuBar {
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.48);
    border-radius: 8px;
    padding: 8px 6px 0px 6px;
}

.menus {
    margin: auto;
    height: 78px;
    width: 98px;
    margin: 2px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.44px;
    cursor: pointer;
}

.menus:hover {
    background: #D8E6FF;
    border-radius: 8px;
}

.homeMenu {
    background: #D8E6FF;
    border-radius: 8px;
}