#toDosContent {
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.48);
    border-radius: 12px;
    padding-top: 0.1px;
    min-width: 344px;
    max-width: 344px;
}

#toDosElement {
    margin: 12px 26px;
}

#myDay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
}

#myDayTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color: #19191D;
}

#myDayDate {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #5A5B6A;
    line-height: 20px;
}

#addToDos {
    width: 97%;
    height: 23px;
    margin-bottom: 10px;
    font-size: 105%;
    padding-left: 5px;
    border-radius: 5px;
    border: 1px solid black;
}


.eachToDo {
    padding: 12px 12px 21px 17px;
    background: #F9F9F9;
    border-radius: 8px;
    margin-bottom: 9px;
    border: black solid 1px;
}

.eachToDoBorder {
    border: black solid 1px !important;
}

.toDoEllpsis {
    display: flex;
    justify-content: flex-end;
}

.toDoInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.44px;
    color: #000000;
}

.toDoDesc {
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.25px;
    color: #5A5B6A;
}