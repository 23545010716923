#headerBar {
    height: 80px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.48);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#aboutUser {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 31px;
}

#shipIcon {
    margin-right: 19px;
}

#title {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.44px;
}

#crewmates {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.25px;
    color: #19191D;
}

#crewmates span {
    font-weight: 550;
}

#time-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

#time {
    padding: 6.09px 16px;
    margin-right: 16px;
    color: #19191D;
    border: 1px solid #B4B4BB;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.44px;
}